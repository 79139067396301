<template>
  <div class="login__wrap">
    <div class="login__main">
      <div class="login__box">
        <div class="login-top">
          <div class="login-title">鹰潭市人才发展集团有限公司</div>
          <div class="login-title-english">Yingtan Talent Development Group Co., Ltd</div>
        </div>
        <el-form
          class="sign__main"
          ref="elForm"
          :model="form.data"
          :rules="form.rules"
          @submit.prevent="submit"
        >
          <el-form-item prop="name">
            <el-input v-model="form.data.name" clearable placeholder="请输入账号" prefix-icon="el-icon-user"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="form.data.password" clearable show-password placeholder="请输入密码" prefix-icon="el-icon-key"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 50px;">
            <el-button class="theneBtn" style="width: 100%;padding: 15px 40px;" type="primary" :loading="form.loading" :disabled="form.loading" @click.stop="submit">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import {setStorage} from '@/storage/index.js'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        data: {
          // name: 'admin',
          // password: 'yt@2022',
          name: '',
          password: '',
        },
        rules: {
          name: [{ required: true, message: '请输入用户名', trigger:'blur' }],
          password: [{ required: true, message: '请输入正确密码', trigger:'blur' }],
        }
      },
    }
  },
  methods: {
     submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          this.$http.post('/admin/admin/login', this.form.data).then(res => {
            if(res.code === 1) {
              setStorage('token', res.data.token)
              setStorage('userName', this.form.data.name)
              this.$router.push('/article/list')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  },
}
</script>
<style scoped lang="scss">
.login__wrap {
  width: 100%;
  height: 100vh;
  background-image: url('~@/assets/img/bg.png');
  background-size:cover;
  
  .login__main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .login-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      .login-title {
        font-size: 21px;
        font-weight: bold;
        text-align: center;
        color: #333;
        margin-top: 0;
      }
      .login-title-english {
        font-size: 13px;
        margin-top: 2px;
      }
    }

    .login__box {
      width: 400px;
      padding: 40px;
      border-radius: 10px;
      background-color: #FFF;
      box-sizing: border-box;
      overflow: hidden;
    }
    .theneBtn {
      background-color: $theme !important;
      border-color: $theme !important;
    }
    
    @include position($t: 50%, $l: 50%);
    transform: translate(-50%, -50%);
    
  }
}
</style>
